<template>
  <div>
    <v-list nav>
      <v-list-item-group color="primary">

        <v-list-item to="/accounts/config/email-templates" exact class="rounded-lg">
          <v-list-item-avatar size="30" tile>
            <v-icon>mdi-email</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Modèles d'emails</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/accounts/config/sms-templates" exact class="rounded-lg">
          <v-list-item-avatar size="30" tile>
            <v-icon>mdi-message-text</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Modèles de SMS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item to="/accounts/config/mail-settings" exact class="rounded-lg">
          <v-list-item-avatar size="30" tile>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Configuration mail</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list-item-group>
    </v-list>

  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>