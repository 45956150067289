<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">

            <NavAccounts/>

            <v-card class="rounded-lg shadow mt-7">

              <v-row>

                <v-col cols="3">
                  <NavListAccounts/>
                </v-col>

                <v-col cols="9">

                  <v-card-text>
                    <div class="text-end">
                      <v-btn class="rounded-lg"
                             color="gifty"
                             dark
                             @click="$refs.smsTemplateFormDialog.open()"
                             depressed
                      >
                        <v-icon left>mdi-plus</v-icon>
                        Ajouter un modèle
                      </v-btn>
                    </div>
                  </v-card-text>

                  <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="50"
                    ></v-progress-circular>
                  </v-overlay>


                  <v-card-text v-if="isLoading">
                    <v-skeleton-loader type="table"/>
                  </v-card-text>

                  <div v-else>
                    <v-card-text>
                      <v-simple-table v-if="smsTemplates.length" class="table-border rounded-lg">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th>Modèle par défaut</th>
                            <th>Nom</th>
                            <th>Contenu</th>
                            <th>Créé le</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr v-for="(template ,index) in smsTemplates" :key="index">

                            <td>
                              <v-icon color="success" v-if="template.is_default">mdi-check-circle</v-icon>
                              <v-icon v-else color="red">mdi-close-circle</v-icon>
                            </td>

                            <td>
                              {{ template.title }}
                            </td>

                            <td v-html="formattedBody(template.body)"></td>

                            <td>
                              {{ template.created_at }}
                            </td>

                            <td>
                              <div class="text-no-wrap">
                                <v-btn icon @click="$refs.smsTemplateFormDialog.open(template)">
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>

                                <v-btn icon color="red"
                                       @click="remove(template.id)">
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                              </div>
                            </td>

                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <div v-else>
                        <NoResults @reload="getSmsTemplates"/>
                      </div>
                    </v-card-text>

                  </div>

                </v-col>

              </v-row>


            </v-card>
          </v-col>
        </v-row>
      </div>

      <SmsTemplateFormDialog ref="smsTemplateFormDialog" @refresh="getSmsTemplates"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import NavAccounts from "@/views/accounts/components/NavAccounts";
import SmsTemplateFormDialog from "@/views/accounts/smsTemplates/SmsTemplateFormDialog";
import NavListAccounts from "@/views/accounts/components/NavListAccounts";

export default {
  components: {NavListAccounts, SmsTemplateFormDialog, NavAccounts, NoResults},
  data() {
    return {
      id: '',
      overlay: false,
      isLoading: false,
      smsTemplates: [],
    };
  },
  methods: {
    getSmsTemplates() {
      this.isLoading = true
      HTTP.get('/v1/sms-templates').then(res => {
        this.smsTemplates = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;
      HTTP.post("/v1/sms-templates/delete", {
        id: this.id,
      }).then(() => {
        this.overlay = false;
        let i = this.smsTemplates.findIndex((el) => el.id === this.id);
        this.$delete(this.smsTemplates, i);
        this.id = null;
        this.$successMessage = "Cet élément a été supprimé avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
    formattedBody(body) {
      return body.replace(/@password/g, '<span class="variable-highlight">@password</span>')
          .replace(/@name/g, '<span class="variable-highlight">@name</span>')
          .replace(/@phone/g, '<span class="variable-highlight">@phone</span>');
    }
  },
  created() {
    this.getSmsTemplates();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    }
  },

};
</script>

<style>
.variable-highlight {
  color: #d60c56;
  font-weight: 500;
}
</style>
