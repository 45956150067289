<template>
  <div>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">

          <v-checkbox v-model="form.is_default"
                      label="Modèle par défaut"
                      :error-messages="errors.is_default"
          ></v-checkbox>


          <v-text-field outlined
                        dense
                        v-model="form.title"
                        :error-messages="errors.title"
                        label="Nom de modèle *"
          />


          <v-expansion-panels flat class="rounded mb-3 grey lighten-5">
            <v-expansion-panel class="grey lighten-5">
              <v-expansion-panel-header>
                <div>
                  <v-icon left color="info">mdi-information</v-icon>
                  Utilisez les variables suivantes pour personnaliser votre message :
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="f-14 grey--text text--darken-1">
                  <ul>
                    <li>
                      <v-chip small dark color="info" draggable @click="copyToClipboard('@password')">@password
                      </v-chip>
                      remplace le mot de passe
                    </li>
                    <li class="py-2">
                      <v-chip small dark color="info" draggable @click="copyToClipboard('@name')">@name</v-chip>
                      remplace le nom d'utilisateur
                    </li>
                    <li>
                      <v-chip small dark color="info" draggable @click="copyToClipboard('@phone')">@phone</v-chip>
                      remplace le numéro de téléphone
                    </li>
                    <li class="mt-2">
                      <v-chip small dark color="info" draggable @click="copyToClipboard('@email')">@email</v-chip>
                      remplace l'adresse e-mail
                    </li>
                  </ul>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-textarea outlined
                      hide-details="auto"
                      v-model="form.body"
                      :error-messages="errors.body"
                      label="Contenu *"
          />


        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="rounded-lg"
                 :loading="isLoading"
                 :disabled="isLoading"
                 color="primary"
                 @click="handleSave"
                 depressed
          >
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      title: null,
      dialog: false,
      isLoading: false,
      form: {
        is_default : false
      },
      errors: {},
    }
  },
  methods: {
    open(template) {
      this.dialog = true
      this.errors = {}
      this.title = template ? "Modifier un modèle" : "Ajouter un modèle"
      this.form = template ?? {
        is_default : false
      }
    },
    close() {
      this.dialog = false
    },
    handleSave() {
      this.errors = {}
      this.isLoading = true
      let url = this.form.id ? "/v1/sms-templates/update" : "/v1/sms-templates/store"
      HTTP.post(url, this.form).then(() => {
        this.isLoading = false
        this.$successMessage = "Cet élément a été enregistré avec succès.";
        this.close();
        this.$emit("refresh");
      }).catch(err => {
        this.errors = err.response.data.errors;
        this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
        this.isLoading = false
        console.log(err)
      })
    },
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.$successMessage = "Copié dans le presse-papiers!"
    }
  }
}
</script>

<style scoped>

</style>