<template>
  <div>
    <v-tabs background-color="transparent" v-model="tab" color="gifty">
      <v-tab tab-value="accounts" @click="navigateTo('/accounts')">
        <v-icon left>mdi-account-group</v-icon>
        Principale
      </v-tab>

      <v-tab tab-value="config" @click="navigateTo('/accounts/config/email-templates')">
        <v-icon left>mdi-cog</v-icon>
        Configuration
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: 'accounts'
    }
  },
  mounted() {
    this.updateTabBasedOnRoute(this.$route.path);
  },
  watch: {
    '$route.path': function (newPath) {
      this.updateTabBasedOnRoute(newPath);
    }
  },
  methods: {
    async navigateTo(path) {
      try {
        await this.$router.push(path);
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },
    updateTabBasedOnRoute(path) {
      const configRoutes = ['/accounts/config/email-templates', '/accounts/config/sms-templates', '/accounts/config/mail-settings'];
      if (path === '/accounts') {
        this.tab = 'accounts';
      } else if (configRoutes.includes(path)) {
        this.tab = 'config';
      } else {
        this.tab = null; // Default or handle as needed
      }
    }
  }
}
</script>

<style scoped>
</style>
